<template>
  <div>
    <div
      v-if="answer.id_users"
      class="bg-gradient-primary text-light p-1 rounded rounded-2 mb-2 mr-4"
    >
      <div class="name font-weight-bold font-medium-3">
        {{ answer.username }}
      </div>
      <p class="text">
        {{ answer.text }}
      </p>
      <div class="datetime font-small-1">
        {{ moment(answer.created).format('DD.MM.YYYY HH:mm') }}
      </div>
    </div>
    <div
      v-if="answer.id_customer_users"
      class="bg-gradient-secondary text-light p-1 rounded rounded-2 mb-2 ml-4"
    >
      <div class="name font-weight-bold font-medium-3">
        {{ answer.customer_user }}
      </div>
      <p class="text">
        {{ answer.text }}
      </p>
      <div class="datetime font-small-1">
        {{ moment(answer.created).format('DD.MM.YYYY HH:mm') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnswerCard',
  props: {
    answer: {
      type: Object,
      require: true,
    },
  },
}
</script>

<style scoped>

</style>
